import React, { useContext, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { auth, generateUserDocument, signInWithGoogle } from "../config";
import { DbUserContext } from "../providers/DbUserProvider";
import userService from "../services/userService";
import styles from "./SignUp.module.css";

const SignUp = (props) => {
  const { refreshDbUser } = useContext(DbUserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [error, setError] = useState(null);
  const approved = false;
  const archived = false;
  let history = useHistory();
  let { orgId } = useParams();
  const createUserWithEmailAndPasswordHandler = async (
    event,
    email,
    password
  ) => {
    event.preventDefault();
    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      const dbUserDoc = await generateUserDocument(user, orgId, {
        displayName,
        approved,
        archived,
      });
      if (dbUserDoc) {
        refreshDbUser(dbUserDoc);
      }
      await userService.addToOrg(user, orgId);
    } catch (error) {
      console.error(`Problem signing up user: ${email}`, error);
      setError("Error Signing up with email and password");
      return;
    }

    setEmail("");
    setPassword("");
    setDisplayName("");
    history.push(`/${orgId}`);
  };

  const googleSignupHandler = async () => {
    let dbUser = await signInWithGoogle(orgId);
    if (dbUser) {
      console.log('refreshing user', dbUser);
      refreshDbUser(dbUser);
      history.push(`/${orgId}`);
    } else {
      setError("Error Signing up with Google");
      return;
    }
  }

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    } else if (name === "displayName") {
      setDisplayName(value);
    }
  };

  return (
    <div className={styles.signInBackground}>
      <div className={styles.signInWrapper}>
        <h1 className={styles.localH1}>Sign Up</h1>
        <div>
          {error !== null && <div className={styles.errorMsg}>{error}</div>}
          <form>
            <div className={styles.labelAndInput}>
              <label htmlFor="displayName">Display Name:</label>
              <input
                type="text"
                name="displayName"
                value={displayName}
                placeholder="E.g: User"
                id="displayName"
                onChange={(event) => onChangeHandler(event)}
              />
            </div>
            <div className={styles.labelAndInput}>
              <label htmlFor="userEmail">Email:</label>
              <input
                type="email"
                name="userEmail"
                value={email}
                placeholder="E.g: user@gmail.com"
                id="userEmail"
                onChange={(event) => onChangeHandler(event)}
              />
            </div>
            <div className={styles.labelAndInput}>
              <label htmlFor="userPassword">Password:</label>
              <input
                type="password"
                name="userPassword"
                value={password}
                placeholder="Your Password"
                id="userPassword"
                onChange={(event) => onChangeHandler(event)}
              />
            </div>
            <button
              onClick={(event) => {
                createUserWithEmailAndPasswordHandler(event, email, password);
              }}
            >
              Sign up
            </button>
          </form>
          <div className={styles.orChasm}>
            <p>or</p>
          </div>

          <button className={styles.googleBtn} onClick={googleSignupHandler}>
            <img alt="google logo" src="/google.jpg" />
            Sign In with Google
          </button>
          <br />
          <p>
            Already have an account? <Link to={`/${orgId}`}>Sign in here</Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default SignUp;
