import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { signOutUser } from "../redux/actions/loginActions";
import { bindActionCreators } from "redux";
import SidebarLayout from "../components/SidebarLayout";
import UserBar from "../components/UserBar";
import ContentContainer from "../components/ContentContainer";
import styles from "./PrayerRequests.module.css";
import PrayerList from "../components/PrayerList";
import { firestore } from "../config";
import { useParams } from "react-router";
import { DbUserContext } from "../providers/DbUserProvider";
import LoadingIcon from "../components/LoadingIcon";
import ViewProvider, { View } from "../providers/ViewProvider";

const PrayerRequests = (props) => {
  const dbUserContext = useContext(DbUserContext);
  const { photoURL, displayName, email } = dbUserContext.dbUser
    ? dbUserContext.dbUser
    : {};
  const { orgId } = useParams();
  const [loadingStatus, updateLoadingStatus] = useState(true);
  const [requests, setRequests] = useState(new Map());
  const PAGE_LIMIT = 50;
  const REQUEST_COLLECTION = "requests";
  const PRIVATE_COLLECTION = "private";
  const CONTACT_DETAIL = "contactDetail";
  useEffect(() => {
    if (orgId) {
      let query = firestore
        .collection(REQUEST_COLLECTION)
        .where("orgId", "==", orgId)
        .where("reviewed", "==", false)
        .where("archived", "==", false)
        .orderBy("date", "asc")
        .limit(PAGE_LIMIT);
      const detachListener = query.onSnapshot((snapshot) => {
        updateLoadingStatus(false);
        snapshot.docChanges().forEach(async (change) => {
          const doc = { ...change.doc.data(), id: change.doc.id };
          let privDataRef = {};
          if (change.type === "added" || change.type === "modified") {
            privDataRef = await firestore
              .collection(REQUEST_COLLECTION)
              .doc(doc.id)
              .collection(PRIVATE_COLLECTION)
              .doc(CONTACT_DETAIL)
              .get();
          }
          setRequests((curRequests) => {
            if (change.type === "added" || change.type === "modified") {
              curRequests.set(doc.id, { ...doc, ...privDataRef.data() });
            }
            if (change.type === "removed") {
              curRequests.delete(doc.id);
            }
            return new Map(curRequests);
          });
        });
      });
      return detachListener;
    }
  }, [orgId]);

  return (
    <ViewProvider view={View.ADMIN}>
      <div>
        <SidebarLayout pageTitle="Prayer Requests">
          <UserBar
            imageUrl={
              photoURL
                ? photoURL
                : "https://www.weact.org/wp-content/uploads/2016/10/Blank-profile.png"
            }
            name={displayName ? displayName : email}
            email={email}
          />
          <div className={styles.grid}>
            <ContentContainer
              title="New Prayer Requests"
              customStyle={{ height: "calc(100vh - 107px)", overflow: "auto" }}
            >
              {loadingStatus ? (
                <LoadingIcon />
              ) : (
                <PrayerList
                  requests={Array.from(requests.values())}
                  adminView={true}
                />
              )}
            </ContentContainer>
          </div>
        </SidebarLayout>
      </div>
    </ViewProvider>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ signOutUser }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    user: state.loginReducer.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrayerRequests);
