export default function env() {
  const hostname = window.location.hostname;
  switch (hostname) {
    case "localhost":
      return devConfig;
    default:
      return prodConfig;
  }
}

const prodConfig = {
  apiBasePath: "https://api.prayerwall.church/",
};

const devConfig = {
  apiBasePath: "https://api.prayerwall.church/",
};

function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}
