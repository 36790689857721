import React, { useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { auth, signInWithGoogle } from "../config";
import { connect } from "react-redux";
import { signInUser, toggleLoginLoading } from "../redux/actions/loginActions";
import { bindActionCreators } from "redux";
import styles from "./SignIn.module.css";
import LoadingIcon from "../components/LoadingIcon";
import { DbUserContext } from "../providers/DbUserProvider";

const SignIn = (props) => {
  const { refreshDbUser } = useContext(DbUserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  let { orgId } = useParams();
  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    props.toggleLoginLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((msg) => {
        props.toggleLoginLoading(false);
        props.dispatch(signInUser(msg.user.uid));
      })
      .catch((error) => {
        props.toggleLoginLoading(false);
        setError("Error signing in with password and email!");
        console.error("Error signing in with password and email", error);
      });
  };

  const signInWithGoogleHandler = async () => {
    let dbUser = await signInWithGoogle(orgId);
    if (dbUser) {
      console.log('refreshing user', dbUser);
      refreshDbUser(dbUser);
      // history.push(`/${orgId}`);
    } else {
      setError("Error Signing up with Google");
      return;
    }
  }
  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <div className={styles.signInBackground}>
      {props && props.loginLoading === true ? (
        <div>
          <h1 style={{ fontWeight: 400 }}>
            <b>PRAYERWALL.</b>CHURCH
          </h1>
          <LoadingIcon />
        </div>
      ) : (
        <div className={styles.signInWrapper}>
          <h1 className={styles.localH1}>Sign In</h1>
          <div>
            {error !== null && <div className={styles.errorMsg}>{error}</div>}
            <form>
              <div className={styles.labelAndInput}>
                <label htmlFor="userEmail" className="block">
                  Email
                </label>
                <input
                  type="email"
                  name="userEmail"
                  value={email}
                  placeholder="E.g: user@gmail.com"
                  id="userEmail"
                  onChange={(event) => onChangeHandler(event)}
                />
              </div>
              <div className={styles.labelAndInput}>
                <label htmlFor="userPassword" className="block">
                  Password
                </label>
                <input
                  type="password"
                  name="userPassword"
                  value={password}
                  placeholder="Your Password"
                  id="userPassword"
                  onChange={(event) => onChangeHandler(event)}
                />
              </div>
              <button
                onClick={(event) => {
                  signInWithEmailAndPasswordHandler(event, email, password);
                }}
              >
                Sign in
              </button>
            </form>
            <div className={styles.orChasm}>
              <p>or</p>
            </div>
            <button className={styles.googleBtn} onClick={signInWithGoogleHandler}>
              <img alt="Google" src="/google.jpg" /> Sign in with Google
            </button>
            <br />
            <p>
              Don't have an account?{" "}
              <Link to={`${orgId}/sign-up`}>Sign up here</Link>
              <br />
              <Link to={`${orgId}/password-reset`}>Forgot Password?</Link>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.loginReducer.user,
    loginLoading: state.loginReducer.loginLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ signInUser }, dispatch),
    ...bindActionCreators({ toggleLoginLoading }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
