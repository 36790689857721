import React, { useContext } from "react";
import { connect } from "react-redux";
import { signOutUser } from "../redux/actions/loginActions";
import { bindActionCreators } from "redux";
import styles from "./Navigation.module.css";
import { Link, matchPath, useLocation } from "react-router-dom";
import { DbUserContext } from "../providers/DbUserProvider";

const Navigation = (props) => {
  const { isAdmin } = useContext(DbUserContext);
  const location = useLocation();

  const matchPathLoc = matchPath(useLocation().pathname, {
    path: "/:orgId",
    exact: false,
  });

  return (
    <div className={styles.navWrap}>
      <nav className={styles.mainNav}>
        {isAdmin() && <Link
            className={
              location.pathname === `/${matchPathLoc.params.orgId}/dashboard`
                ? styles.activeLink
                : styles.inactiveLink
            }
            to={"/" + matchPathLoc.params.orgId + "/dashboard"}
          >
            Prayer Requests
          </Link>
        }
        {isAdmin() && <Link
            className={
              location.pathname ===
              `/${matchPathLoc.params.orgId}/manage-users`
                ? styles.activeLink
                : styles.inactiveLink
            }
            to={"/" + matchPathLoc.params.orgId + "/manage-users"}
          >
            Manage Users
          </Link>
        }
        <Link
          className={
            useLocation().pathname ===
            `/${matchPathLoc.params.orgId}/prayer-wall`
              ? styles.activeLink
              : styles.inactiveLink
          }
          to={"/" + matchPathLoc.params.orgId + "/prayer-wall"}
        >
          Prayer Wall
        </Link>
        { isAdmin() && <Link
            className={
              location.pathname === `/${matchPathLoc.params.orgId}/archive`
                ? styles.activeLink
                : styles.inactiveLink
            }
            to={"/" + matchPathLoc.params.orgId + "/archive"}
          >
            Prayer Archive
          </Link>
        }
        { isAdmin() && <Link
            className={
              location.pathname === `/${matchPathLoc.params.orgId}/user-archive`
                ? styles.activeLink
                : styles.inactiveLink
            }
            to={"/" + matchPathLoc.params.orgId + "/user-archive"}
          >
            User Archive
          </Link>
        }
      </nav>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ signOutUser }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    user: state.loginReducer.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
