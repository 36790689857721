export const colorSpread = [
  "#52D4EF",
  "#52ef84",
  "#ef5292",
  "#b452ef",
  "#5272ef",
  "#4edde0",
  "#a0e04e",
  "#f3be21",
  "#f37b21",
  "#e84936",
  "#d858d3",
  "#ffe200",
  "#32d8b4",
];

export const darkBlue = "#1d97b1";
export const lightBlue = "#52d4ef";
