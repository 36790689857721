import React, { useContext } from "react";
import styles from "./UserBar.module.css";
import { auth } from "../config";
import { connect } from "react-redux";
import { signOutUser, toggleLoginLoading } from "../redux/actions/loginActions";
import { bindActionCreators } from "redux";
import { darkBlue } from "../ColorPalette";
import { DbUserContext } from "../providers/DbUserProvider";

const UserBar = (props) => {
  let dbUser = useContext(DbUserContext);
  const signOutUserHandler = (event, props) => {
    props.dispatch(toggleLoginLoading(true));
    auth
      .signOut()
      .then((msg) => {
        props.dispatch(toggleLoginLoading(false));
        dbUser.signOut();
        props.dispatch(signOutUser());
      })
      .catch((error) => {
        props.dispatch(toggleLoginLoading(false));
        console.log("error: ", error);
      });
  };
  return (
    <div className={styles.userBar}>
      <div
        className={styles.signOutText}
        style={{ color: darkBlue }}
        onClick={(event) => signOutUserHandler(event, props)}
      >
        sign out
      </div>
      <img
        alt="profile-avatar"
        className={styles.profilePic}
        src={props.imageUrl}
      />
      {props.name}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ signOutUser }, dispatch),
    ...bindActionCreators({ toggleLoginLoading }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    user: state.loginReducer.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserBar);
