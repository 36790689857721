import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../providers/UserProvider";
import { connect } from "react-redux";
import { signOutUser } from "../redux/actions/loginActions";
import { bindActionCreators } from "redux";
import ContentContainer from "../components/ContentContainer";
import SidebarLayout from "../components/SidebarLayout";
import UserBar from "../components/UserBar";
import styles from "./UserArchive.module.css";
import UserTile from "../components/UserTile";
import { firestore } from "../config";
import { useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import LoadingIcon from "../components/LoadingIcon";

const ManageUsers = (props, { content }) => {
  const { addToast } = useToasts();

  const [appUsers, updateAppUsers] = useState(new Map());
  const [loadingStatus, updateLoadingStatus] = useState(true);
  const USER_COLLECTION = "users";
  const user = useContext(UserContext);
  const { photoURL, displayName, email } = user;
  const { orgId } = useParams();

  useEffect(() => {
    if (orgId) {
      let query = firestore
        .collection(USER_COLLECTION)
        .where("orgId", "==", orgId)
        .where("archived", "==", true)
        .orderBy("submitDate", "desc")
        .limit(100);

      const detachListener = query.onSnapshot((snapshot) => {
        updateLoadingStatus(false);
        snapshot.docChanges().forEach(async (change) => {
          const doc = { ...change.doc.data(), id: change.doc.id };

          updateAppUsers((curRequests) => {
            if (change.type === "added" || change.type === "modified") {
              curRequests.set(doc.id, { ...doc });
            }
            if (change.type === "removed") {
              curRequests.delete(doc.id);
            }
            return new Map(curRequests);
          });
        });
      });

      return detachListener;
    }
  }, [orgId]);

  const unarchiveHandler = (userUnderReview) => {
    let archived = false;

    updateLoadingStatus(true);

    firestore
      .collection(USER_COLLECTION)
      .doc(userUnderReview.id)
      .update({ archived })
      .then(() => {
        updateLoadingStatus(false);
        addToast(content, {
          content: `${userUnderReview.displayName} has been re-activated.`,
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        updateLoadingStatus(false);
        addToast(content, {
          content: `There was a problem re-activating ${userUnderReview.displayName}.`,
          appearance: "error",
          autoDismiss: true,
        });
        console.log({ error });
      });
  };

  const appUsersArr = Array.from(appUsers.values());

  return (
    <SidebarLayout pageTitle="User Archive">
      <UserBar
        imageUrl={
          photoURL
            ? photoURL
            : "https://www.weact.org/wp-content/uploads/2016/10/Blank-profile.png"
        }
        name={displayName}
        email={email}
      />
      <div className={styles.grid}>
        <ContentContainer
          title="Archived Users"
          customStyle={{ height: "calc(50vh - 107px)", overflow: "auto" }}
        >
          {loadingStatus === true ? (
            <LoadingIcon />
          ) : appUsersArr.length > 0 ? (
            appUsersArr.map((user) => {
              return (
                <UserTile
                  key={user.id}
                  user={user}
                  unarchive={unarchiveHandler}
                />
              );
            })
          ) : (
            <div key={user.id} className={styles.noResults}>
              No Archived users.
            </div>
          )}
        </ContentContainer>
      </div>
    </SidebarLayout>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ signOutUser }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    user: state.loginReducer.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
