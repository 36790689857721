import { useLocalState } from "../hooks/hooks";
import Prayer from "./Prayer";

export default function PrayerList(props) {
  const [prayedFor, setPrayedFor] = useLocalState("prayedFor", {});
  const addToPrayedFor = (id, flag) => {
    setPrayedFor({ ...prayedFor, [id]: flag });
  };

  const prayers = props.requests;
  const listItems = prayers.map((prayer) => {
    const options = {
      dateStyle: "long",
      timeStyle: "short",
    };
    const dateWithoutSecs = prayer.date.toDate().toLocaleString([], options);

    return (
      <Prayer
        key={prayer.id}
        firstName={prayer.name ? prayer.name : "Anonymous"}
        date={dateWithoutSecs}
        prayerText={prayer.prayerRequest}
        prayedFor={prayedFor[prayer.id]}
        prayersCount={prayer.prayersCount}
        id={prayer.id}
        email={prayer.email}
        phone={prayer.phone}
        public={prayer.public}
        anonymous={prayer.anonymous}
        reviewed={prayer.reviewed}
        adminView={props.adminView}
        color={prayer.avatarColor}
        setPrayedFor={addToPrayedFor}
        customStyle={props.customStyle}
        onArchive={props.onArchive}
      />
    );
  });
  return listItems;
}
