import React, { useEffect, useRef } from "react";
import styles from "./ContentContainer.module.css";

export default function ContentContainer(props) {
  const scrollRef = useRef(null);

  useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
  }, [props.scrollTrigger]);

  return (
    <div
      className={styles.contentContainerWrapper}
      style={props.customWrapperStyle}
    >
      {props.title.length ? (
        <h2 className={styles.containerHeader}>{props.title}</h2>
      ) : (
        ""
      )}
      <div
        ref={scrollRef}
        className={styles.contentContainer}
        style={props.customStyle}
      >
        {props.children}
      </div>
    </div>
  );
}
