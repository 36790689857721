import React, { useEffect, useRef } from "react";
import styles from "./UserTile.module.css";
import {
  faAngleDown,
  faCheck,
  faTrash,
  faTrashRestore,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserTile = (props) => {
  const [MEMBER, ADMIN] = ["member", "admin"];
  const selectElement = useRef(null);
  const checkmark = useRef(null);

  useEffect(() => {
    if (props.user) {
      if (props.user.admin) {
        selectElement.current.value = ADMIN;
      } else if (props.user.member) {
        selectElement.current.value = MEMBER;
      }
    }
  }, [props.user, selectElement, MEMBER, ADMIN]);

  return (
    <div className={styles.userContainer}>
      {/* ICON, NAME AND DATE */}
      <div className={styles.iconNameDate}>
        <div
          className={styles.letterIcon}
          style={
            props.user.photoURL && props.user.photoURL.length > 0
              ? { backgroundImage: `url(${props.user.photoURL})` }
              : { background: props.user.avatarColor }
          }
        >
          {props.user.photoURL && props.user.photoURL.length > 0
            ? ""
            : props.user.email.charAt(0).toUpperCase()}
        </div>
        <div>
          <div>
            <h3 className={styles.name}>{props.user.displayName}</h3>
            <div>{props.user.email}</div>
          </div>
          <div>{props.user.submitDate.toDate().toLocaleString()}</div>
        </div>
      </div>
      {props.user.archived && (
        <div className={styles.buttonBank}>
          <div className={`${styles.buttonWrapper} ${styles.trashIcon}`}>
            <FontAwesomeIcon
              title="Restore"
              className={styles.icon}
              icon={faTrashRestore}
              onClick={() => {
                props.unarchive(props.user);
              }}
            />
          </div>
        </div>
      )}
      {!props.user.archived && (
        <div className={styles.buttonBank}>
          <div className={`${styles.buttonWrapper} ${styles.trashIcon}`}>
            <FontAwesomeIcon
              title="Archive"
              className={styles.icon}
              icon={faTrash}
              onClick={() => {
                props.archive(props.user);
              }}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <div className={styles.dropdownButton}>
              <select
                onChange={() => {
                  checkmark.current.setAttribute("disabled", false);
                  checkmark.current.disabled = false;
                  selectElement.current.style.color = "#444";
                }}
                name="userType"
                id="userType"
                defaultValue="select role"
                ref={selectElement}
              >
                <option value="select role" disabled>
                  select role
                </option>
                <option
                  value={ADMIN}
                  onSelect={(e) =>
                    (selectElement.current.value = e.target.value)
                  }
                >
                  Admin
                </option>
                <option
                  value={MEMBER}
                  onSelect={(e) =>
                    (selectElement.current.value = e.target.value)
                  }
                >
                  Member
                </option>
              </select>
              <div className={styles.carrotIcon}>
                <FontAwesomeIcon
                  className={styles.icon}
                  icon={faAngleDown}
                  onClick={() => {}}
                />
              </div>
              <div className={styles.iconContainer} disabled ref={checkmark}>
                <FontAwesomeIcon
                  title="Approve"
                  onClick={() =>
                    props.confirm(props.user, selectElement.current)
                  }
                  className={styles.icon}
                  icon={faCheck}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default UserTile;
