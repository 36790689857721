
const Splash = (props) => {
    return (
        <div>
            <h1>Splash Page</h1>
            <p>Maybe some info about the service.</p>
            <p>Maybe direct them to enter their orgId and go to their org?</p>
        </div>
    );
}

export default Splash;