import React from "react";
import { colorSpread } from "../ColorPalette";
import styles from "./Prayer.module.css";
import PrayerButtons from "./PrayerButtons";
import { firestore } from "../config";
import firebase from "firebase/app";

export default function Prayer(props) {
  const REQUESTS_COLLECTION = "requests";

  const approveBtnHandler = () => {
    firestore
      .collection(REQUESTS_COLLECTION)
      .doc(props.id)
      .update({
        reviewed: true,
        archived: false,
        reviewedDate: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {})
      .catch((err) => console.error("Problem approving request", err));
  };

  const prayedForBtnHandler = () => {
    props.setPrayedFor(props.id, props.prayedFor ? !props.prayedFor : true);
  };

  const archiveBtnHandler = () => {
    firestore
      .collection(REQUESTS_COLLECTION)
      .doc(props.id)
      .update({
        archived: true,
        reviewed: false, // A hack since I don't want to change the query at this moment for the other pages
      })
      .then(() => {
        if (props.onArchive) props.onArchive();
      })
      .catch((err) => console.error("Problem archiving request", err));
  };

  const phoneDisplay = (phone) => {
    const stripNum = phone.replace(/\D/g, "");
    let finalNum = "";

    if (stripNum.length === 10) {
      finalNum = `(${stripNum.slice(0, 3)}) ${stripNum.slice(
        3,
        6
      )}-${stripNum.slice(6, 10)}`;
    } else if (stripNum.length === 7) {
      finalNum = `${stripNum.slice(0, 3)}-${stripNum.slice(3, 7)}`;
    } else {
      finalNum = stripNum;
    }

    return finalNum;
  };

  const phoneStrip = (phone) => {
    return phone.replace(/\D/g, "");
  };

  return (
    <div className={styles.prayerContainer} style={props.customStyle}>
      <div className={styles.iconNameDate}>
        <div
          className={styles.letterIcon}
          style={
            !props.color
              ? {
                  background:
                    colorSpread[Math.floor(Math.random() * colorSpread.length)],
                }
              : { background: props.color }
          }
        >
          {props.firstName.charAt(0).toUpperCase()}
        </div>
        <div>
          <div>
            <div className={styles.nameAndStatus}>
              <h3 className={styles.name}>
                {props.firstName} {props.lastName}{" "}
              </h3>
              <div className={styles.pillBadges}>
                {!props.public && props.adminView && <div>private</div>}
                {props.anonymous && props.adminView && <div>anonymous</div>}
              </div>
            </div>
          </div>
          <div>{props.date}</div>
          {(props.email || props.phone) && (
            <div className={styles.contactInfo}>
              <a href={`mailto:${props.email}`}>{props.email}</a>
              <span className={styles.divider}>|</span>
              <a href={`tel:${phoneStrip(props.phone)}`}>
                {phoneDisplay(props.phone)}
              </a>
            </div>
          )}
        </div>
      </div>

      <div className={styles.prayerText}>{props.prayerText}</div>
      <PrayerButtons
        prayedFor={props.prayedFor}
        archiveBtnHandler={archiveBtnHandler}
        prayerBtnHandler={prayedForBtnHandler}
        approveBtnHandler={approveBtnHandler}
      />
    </div>
  );
}
