import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { signOutUser } from "../redux/actions/loginActions";
import { bindActionCreators } from "redux";
import ContentContainer from "../components/ContentContainer";
import styles from "./MemberPrayerWall.module.css";
import Prayers from "../components/PrayerList";
import SidebarLayout from "../components/SidebarLayout";
import UserBar from "../components/UserBar";
import { useParams } from "react-router-dom";
import { firestore } from "../config";
import { DbUserContext } from "../providers/DbUserProvider";
import ViewProvider, { View } from "../providers/ViewProvider";

const PrayerWall = (props) => {
  const dbUserContext = useContext(DbUserContext);
  const { photoURL, displayName, email } = dbUserContext.dbUser
    ? dbUserContext.dbUser
    : {};
  let { orgId } = useParams();
  const REQUEST_COLLECTION = "requests";
  const PAGE_LIMIT = 10;
  const pageRef = useRef({ startRef: null, prevRef: null, nextRef: null });
  const [requests, setRequests] = useState([]);
  const [morePages, setMorePages] = useState(false);

  const initialState = { pageNum: 1, nextPageState: "" };
  const reducer = (state, action) => {
    switch (action.type) {
      case "NEXT":
        return {
          ...state,
          pageNum: state.pageNum + 1,
          nextPageState: action.type,
        };
      case "PREV":
        let pageNum = state.pageNum <= 2 ? 1 : state.pageNum - 1;
        let page = pageNum === 1 ? "FIRST" : action.type;
        return { ...state, pageNum: pageNum, nextPageState: page };
      case "REFRESH":
        // TODO: This will probably seem weird if you're on the last page, archive all requests, then get sent back to page 1 without the pagenum changing
        pageRef.current.startRef = firestore
          .collection(REQUEST_COLLECTION)
          .doc(requests[0].id);
        return { ...state, nextPageState: "REFRESH" };
      default:
        throw new Error();
    }
  };
  const [pageState, dispatchPage] = useReducer(reducer, initialState);
  const refresh = () => dispatchPage({ type: "REFRESH" });
  useEffect(() => {
    // We're querying 1 extra so that we know if there are more pages.
    // We'll shave it off when displaying though.
    let baseQuery = firestore
      .collection(REQUEST_COLLECTION)
      .where("orgId", "==", orgId)
      .where("reviewed", "==", true)
      .orderBy("reviewedDate", "desc")
      .limit(PAGE_LIMIT + 1);

    const setQueryStart = (query) => {
      switch (pageState.nextPageState) {
        case "NEXT":
          return query.startAfter(pageRef.current.nextRef);
        case "PREV":
          return query.endBefore(pageRef.current.prevRef);
        case "REFRESH":
          return query.startAt(pageRef.current.startRef);
        default:
          return query;
      }
    };

    let query = setQueryStart(baseQuery);
    query
      .get()
      .then((snapshots) => {
        const len = snapshots.docs.length;
        if (len > 0) {
          const offset = len > PAGE_LIMIT ? 2 : 1;
          const sliceOffset = len > PAGE_LIMIT ? 1 : 0;
          pageRef.current.prevRef = snapshots.docs[0];
          pageRef.current.nextRef =
            snapshots.docs[snapshots.docs.length - offset];
          setRequests(
            snapshots.docs.slice(0, len - sliceOffset).map((doc) => {
              return {
                ...doc.data(),
                date: doc.data().reviewedDate,
                id: doc.id,
              };
            })
          );
        }
        if (len > PAGE_LIMIT) setMorePages(true);
        else setMorePages(false);
      })
      .catch((err) => console.error("Problem fetching requests", err));
  }, [pageState, orgId]);

  return (
    <ViewProvider view={View.MEMBER}>
      {console.log("REEEEEEEEEEEEEEEEEEEEEEE")}
      <div>
        <SidebarLayout>
          <UserBar
            imageUrl={
              photoURL
                ? photoURL
                : "https://www.weact.org/wp-content/uploads/2016/10/Blank-profile.png"
            }
            name={displayName ? displayName : email}
            email={email}
          />
          <div className={styles.grid}>
            <div className={styles.buttonBank}>
              <button
                onClick={() => dispatchPage({ type: "PREV" })}
                className={pageState.pageNum <= 1 ? "disabled" : ""}
                disabled={pageState.pageNum <= 1}
              >
                Prev
              </button>
              <button
                onClick={() => dispatchPage({ type: "NEXT" })}
                className={!morePages ? "disabled" : ""}
                disabled={!morePages}
              >
                Next
              </button>
            </div>
            <ContentContainer
              title="Prayer Wall"
              customStyle={{ height: "calc(100vh - 107px)", overflow: "auto" }}
              scrollTrigger={requests}
            >
              {requests.length > 0 ? (
                <Prayers
                  requests={requests}
                  displayIcons={false}
                  onArchive={refresh}
                />
              ) : (
                <div className={styles.noResults}>No Prayer Requests.</div>
              )}
            </ContentContainer>
          </div>
        </SidebarLayout>
      </div>
    </ViewProvider>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ signOutUser }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    user: state.loginReducer.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrayerWall);
