import React from "react";
import styles from "./LoadingIcon.module.css";

export default function LoadingIcon() {
  return (
    <div className={styles.container}>
      <div className={styles.animationWrapper}>
        <div className={[styles.brick1, styles.brick].join(" ")}></div>
        <div className={[styles.brick2, styles.brick].join(" ")}></div>
        <div className={[styles.brick3, styles.brick].join(" ")}></div>
        <div className={[styles.brick4, styles.brick].join(" ")}></div>
      </div>
      <div style={{ marginLeft: "8px", color: "gray" }}>loading...</div>
    </div>
  );
}
