import React, {
  useEffect,
  useReducer,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import { connect } from "react-redux";
import { signOutUser } from "../redux/actions/loginActions";
import { bindActionCreators } from "redux";
import ContentContainer from "../components/ContentContainer";
import styles from "./PrayerWall.module.css";
import Prayers from "../components/PrayerList";
import PrayerRequestForm from "../components/PrayerRequestForm";
import { useParams } from "react-router-dom";
import { firestore } from "../config";
import firebase from "firebase/app";
import { colorSpread } from "../ColorPalette";
import ViewProvider, { View } from "../providers/ViewProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const PrayerWall = (props) => {
  let { orgId } = useParams();
  const REQUEST_COLLECTION = "requests";
  const PRIVATE_COLLECTION = "private";
  const CONTACT_DETAIL = "contactDetail";
  const PAGE_LIMIT = 10;
  const pageRef = useRef({ startRef: null, prevRef: null, nextRef: null });
  const [requests, setRequests] = useState([]);
  const [morePages, setMorePages] = useState(false);

  const initialState = { pageNum: 1, nextPageState: "" };
  const reducer = (state, action) => {
    switch (action.type) {
      case "NEXT":
        return {
          ...state,
          pageNum: state.pageNum + 1,
          nextPageState: action.type,
        };
      case "PREV":
        let pageNum = state.pageNum <= 2 ? 1 : state.pageNum - 1;
        let page = pageNum === 1 ? "FIRST" : action.type;
        return { ...state, pageNum: pageNum, nextPageState: page };
      case "REFRESH":
        // TODO: This will probably seem weird if you're on the last page, archive all requests, then get sent back to page 1 without the pagenum changing
        pageRef.current.startRef = firestore
          .collection(REQUEST_COLLECTION)
          .doc(requests[0].id);
        return { ...state, nextPageState: "REFRESH" };
      default:
        throw new Error();
    }
  };
  const [pageState, dispatchPage] = useReducer(reducer, initialState);
  const refresh = () => dispatchPage({ type: "REFRESH" });

  useEffect(() => {
    // We're querying 1 extra so that we know if there are more pages.
    // We'll shave it off when displaying though.
    const baseQuery = firestore
      .collection(REQUEST_COLLECTION)
      .where("orgId", "==", orgId)
      .where("public", "==", true)
      .where("reviewed", "==", true)
      .orderBy("reviewedDate", "desc")
      .limit(PAGE_LIMIT + 1);

    const setQueryStart = (query) => {
      switch (pageState.nextPageState) {
        case "NEXT":
          return query.startAfter(pageRef.current.nextRef);
        case "PREV":
          return query.endBefore(pageRef.current.prevRef);
        case "REFRESH":
          return query.startAt(pageRef.current.startRef);
        default:
          return query;
      }
    };

    //Effect start
    let query = setQueryStart(baseQuery);
    query
      .get()
      .then((snapshots) => {
        const len = snapshots.docs.length;
        if (len > 0) {
          const offset = len > PAGE_LIMIT ? 2 : 1;
          const sliceOffset = len > PAGE_LIMIT ? 1 : 0;
          pageRef.current.prevRef = snapshots.docs[0];
          pageRef.current.nextRef =
            snapshots.docs[snapshots.docs.length - offset];
          setRequests(
            snapshots.docs.slice(0, len - sliceOffset).map((doc) => {
              return {
                ...doc.data(),
                date: doc.data().reviewedDate,
                id: doc.id,
              };
            })
          );
        }
        if (len > PAGE_LIMIT) setMorePages(true);
        else setMorePages(false);
      })
      .catch((err) => console.error("Problem fetching requests", err));
  }, [pageState, orgId]);

  const submitHandler = async (data) => {
    const name = data.anonymous ? "Anonymous" : data.name;
    const request = {
      anonymous: data.anonymous,
      date: firebase.firestore.FieldValue.serverTimestamp(),
      name: name,
      orgId: orgId,
      prayerRequest: data.prayerRequest,
      prayersCount: 0,
      public: data.public,
      reviewed: false,
      archived: false,
      avatarColor: colorSpread[Math.floor(Math.random() * colorSpread.length)],
    };
    const contactDetailRequest = {
      name: data.name,
      phone: data.phone,
      email: data.email,
    };
    try {
      let batch = firestore.batch();
      // Create prayer request doc
      const requestRef = firestore.collection(REQUEST_COLLECTION).doc();
      batch.set(requestRef, request);

      // Create private contact detail
      const contactDetailRef = requestRef
        .collection(PRIVATE_COLLECTION)
        .doc(CONTACT_DETAIL);
      batch.set(contactDetailRef, contactDetailRequest);
      return batch.commit();
    } catch (e) {
      console.error("Problem saving request", e);
      return Promise.reject(e);
    }
  };

  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };

  const [width, height] = useWindowSize();

  return (
    <ViewProvider view={View.PUBLIC}>
      <div className={styles.prayerWallWrapper}>
        <PrayerRequestForm orgId={orgId} submitHandler={submitHandler} />
        <div className={styles.buttonBank}>
          <button
            onClick={() => dispatchPage({ type: "PREV" })}
            className={pageState.pageNum <= 1 ? "disabled" : ""}
            disabled={pageState.pageNum <= 1}
          >
            {width > 720 ? "Prev" : <FontAwesomeIcon icon={faChevronLeft} />}
          </button>
          <button
            onClick={() => dispatchPage({ type: "NEXT" })}
            className={!morePages ? "disabled" : ""}
            disabled={!morePages}
          >
            {width > 720 ? "Next" : <FontAwesomeIcon icon={faChevronRight} />}
          </button>
        </div>
        <div className={styles.grid}>
          <ContentContainer
            title=""
            scrollTrigger={requests}
            customStyle={{
              height: "calc(100vh - 125px)",
              minHeight: "700px",
              overflow: "auto",
              boxShadow: "none",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Prayers
              customStyle={{
                width: "calc(50% - 20px)",
                margin: 10,
                flexBasis: 320,
                flexGrow: 1,
                flexShrink: 0,
              }}
              requests={requests}
              displayIcons={false}
              onArchive={refresh}
            />
          </ContentContainer>
        </div>
      </div>
    </ViewProvider>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ signOutUser }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    user: state.loginReducer.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrayerWall);
