import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { colorSpread } from "./ColorPalette";
import userService from "./services/userService";

export const config = {
  apiKey: "AIzaSyDZ0ZmPNFjVC3qF1u5xII7wredsbvz1KkE",
  authDomain: "prayerwall.church",
  projectId: "prayer-wall-7abf8",
  storageBucket: "prayer-wall-7abf8.appspot.com",
  messagingSenderId: "428805073683",
  appId: "1:428805073683:web:0d40e3bd807c5693f748e6",
  measurementId: "G-RM790LV8NJ",
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = async (orgId) => {
  try {
    let credential = await auth.signInWithPopup(provider);
    let user = credential.user;
    await userService.createUser(user, {orgId, avatarColor: generateAvatarColor()});
    let dbUser = await firestore.collection('users').doc(user.uid).get();
    return {uid: user.uid, ...dbUser.data()};
  } catch (error) {
    console.error('Problem logging in user with Google', error);
    return;
  }
};

export const generateAvatarColor = () => {
  return colorSpread[Math.floor(Math.random() * colorSpread.length)];
}

export const generateUserDocument = async (user, orgId, additionalData) => {
  if (!user) return;
  // If user is a duplicate, the create new user call will fail.
  const userRef = firestore.doc(`users/${user.uid}`);
  const { email, displayName, photoURL, approved } = user;
  try {
    await userRef.set({
      displayName,
      email,
      photoURL,
      approved,
      submitDate: firebase.firestore.FieldValue.serverTimestamp(),
      avatarColor:
        colorSpread[Math.floor(Math.random() * colorSpread.length)],
      orgId,
      ...additionalData,
    });
  } catch (error) {
    console.error("Error creating user document", error);
  }
  return getUserDocument(user.uid);
};

const getUserDocument = async (uid) => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    return {
      uid,
      ...userDocument.data(),
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};
