import { SIGN_IN, SIGN_OUT, LOGIN_LOADING } from "../actions";

const initialState = {
  user: "",
  loginLoading: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case SIGN_OUT: {
      return {
        ...state,
        user: "",
      };
    }
    case LOGIN_LOADING: {
      return {
        ...state,
        loginLoading: action.status,
      };
    }
    default:
      return state;
  }
};

export default loginReducer;
