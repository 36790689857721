import { useContext, useEffect, useState, createContext } from "react";
import { firestore } from "../config";
import { UserContext } from "./UserProvider";

const INITIAL = {dbUser:null, signOut:() => {}, isAdmin:() => false, isMember:() => false};
export const DbUserContext = createContext(INITIAL);


export default function DbUserProvider(props) {
    const user = useContext(UserContext);
    const [dbUser, setDbUser] = useState(INITIAL);

    const signOut = () => {setDbUser(INITIAL) };

    const isAdmin = (user) => {return () => {
        if (!user) return false;
        return user.admin === true || user.superAdmin === true;
    }};

    const isMember = (user) => {return () => {
        if (!user) return false;
        return user.member === true || user.superAdmin === true;
    }};

    const getUser = (user) => {
        return firestore.collection('users').doc(user.uid).get();
    }


    useEffect(() => {
        const refreshDbUser = (dbUserData) => {
            setDbUser({dbUser:dbUserData, signOut: signOut,
                isAdmin:isAdmin(dbUserData), isMember:isMember(dbUserData), refreshDbUser:refreshDbUser})
        }

        if (user) {
            getUser(user)
                .then((doc) => refreshDbUser(doc.data()))
                .catch((err) => console.log('Problem getting db user. This can happen as normal behavior during new signup', err));
        } else {
            setDbUser({...INITIAL, refreshDbUser});
        }
    }
    ,[user])

    return (
        <DbUserContext.Provider value={dbUser}>
            {props.children}
        </DbUserContext.Provider>
    );
}