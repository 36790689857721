import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../providers/UserProvider";
import { connect } from "react-redux";
import { signOutUser } from "../redux/actions/loginActions";
import { bindActionCreators } from "redux";
import ContentContainer from "../components/ContentContainer";
import SidebarLayout from "../components/SidebarLayout";
import UserBar from "../components/UserBar";
import styles from "./ManageUsers.module.css";
import UserTile from "../components/UserTile";
import { firestore } from "../config";
import { useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import LoadingIcon from "../components/LoadingIcon";
import userService from "../services/userService";

const ManageUsers = (props, { content }) => {
  const { addToast } = useToasts();

  const [appUsers, updateAppUsers] = useState(new Map());
  const [loadingStatus, updateLoadingStatus] = useState(true);
  const USER_COLLECTION = "users";
  const user = useContext(UserContext);
  const { photoURL, displayName, email } = user;
  const { orgId } = useParams();

  useEffect(() => {
    if (orgId) {
      let query = firestore
        .collection(USER_COLLECTION)
        .where("orgId", "==", orgId)
        .where("archived", "==", false)
        .orderBy("submitDate", "desc")
        .limit(100);

      const detachListener = query.onSnapshot((snapshot) => {
        updateLoadingStatus(false);
        snapshot.docChanges().forEach(async (change) => {
          const doc = { ...change.doc.data(), id: change.doc.id };

          updateAppUsers((curRequests) => {
            if (change.type === "added" || change.type === "modified") {
              curRequests.set(doc.id, { ...doc });
            }
            if (change.type === "removed") {
              curRequests.delete(doc.id);
            }
            return new Map(curRequests);
          });
        });
      });

      return detachListener;
    }
  }, [orgId]);

  const archiveHandler = async (userUnderReview) => {

    updateLoadingStatus(true);
    try {
      await userService.archive(user, userUnderReview.id);
      updateLoadingStatus(false);
      addToast(content, {
        content: `${userUnderReview.displayName} has been archived.`,
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      console.error("Problem archiving user", err);
      updateLoadingStatus(false);
      addToast(content, {
        content: `There was a problem archiving ${userUnderReview.displayName}`,
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const confirmHandler = async (userUnderReview, selectedRole) => {
    let approved = true;
    updateLoadingStatus(true);

    try {
      await firestore
        .collection(USER_COLLECTION)
        .doc(userUnderReview.id)
        .update({ approved });
    } catch (err) {
      updateLoadingStatus(false);
      console.error("Error approving user", err);
      addToast(content, {
        content: `Error approving ${userUnderReview.displayName}`,
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    if (selectedRole.value === "member") {
      await Promise.all([
        userService.removeAdmin(user, userUnderReview.id),
        userService.grantMember(user, userUnderReview.id),
      ])
        .then(() => {
          addToast(content, {
            content: `${userUnderReview.displayName} was made a Member`,
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch((err) => {
          console.error("Problem granting Member access", err);
          addToast(content, {
            content: `Error approving ${userUnderReview.displayName} as a Member`,
            appearance: "error",
            autoDismiss: true,
          });
        });
    } else if (selectedRole.value === "admin") {
      Promise.all([
        userService.removeMember(user, userUnderReview.id),
        userService.grantAdmin(user, userUnderReview.id),
      ])
        .then(() => {
          addToast(content, {
            content: `${userUnderReview.displayName} was made an Admin`,
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch((err) => {
          console.error(
            `Error approving ${userUnderReview.displayName} as an Admin`,
            err
          );
          addToast(content, {
            content: `Error approving ${userUnderReview.displayName} as an Admin`,
            appearance: "error",
            autoDismiss: true,
          });
        });
    }

    updateLoadingStatus(false);
    return;
  };

  const appUsersArr = Array.from(appUsers.values());

  return (
    <SidebarLayout pageTitle="Manage Users">
      <UserBar
        imageUrl={
          photoURL
            ? photoURL
            : "https://www.weact.org/wp-content/uploads/2016/10/Blank-profile.png"
        }
        name={displayName}
        email={email}
      />
      <div className={styles.grid}>
        <ContentContainer
          title="New Users"
          customStyle={{ height: "calc(50vh - 107px)", overflow: "auto" }}
        >
          {loadingStatus === true ? (
            <LoadingIcon />
          ) : appUsersArr.length > 0 ? (
            appUsersArr.map((user) => {
              if (!user.approved) {
                return (
                  <UserTile
                    key={user.id}
                    user={user}
                    confirm={confirmHandler}
                    archive={archiveHandler}
                  />
                );
              }
            })
          ) : (
            <div key={user.id} className={styles.noResults}>
              No new users.
            </div>
          )}

          {loadingStatus === true ? (
            ""
          ) : appUsersArr.length === 0 ||
            appUsersArr.findIndex((user) => {
              return user.approved === false;
            }) === -1 ? (
            <div key={user.id} className={styles.noResults}>
              No new users.
            </div>
          ) : (
            ""
          )}
        </ContentContainer>
        <ContentContainer
          title="All Users"
          customStyle={{ height: "calc(50vh - 107px)", overflow: "auto" }}
        >
          {loadingStatus === true ? (
            <LoadingIcon />
          ) : appUsersArr.length > 0 ? (
            appUsersArr.map((user) => {
              if (user.approved) {
                return (
                  <UserTile
                    key={user.id}
                    user={user}
                    confirm={confirmHandler}
                    archive={archiveHandler}
                  />
                );
              }
            })
          ) : (
            ""
          )}
          {loadingStatus === true ? (
            ""
          ) : appUsersArr.length === 0 ||
            appUsersArr.findIndex((user) => {
              return user.approved === true;
            }) === -1 ? (
            <div key={user.id} className={styles.noResults}>
              No users to display.
            </div>
          ) : (
            ""
          )}
        </ContentContainer>
      </div>
    </SidebarLayout>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ signOutUser }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    user: state.loginReducer.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
