import { SIGN_IN, SIGN_OUT, LOGIN_LOADING } from "./index";

export const signInUser = (user) => ({
  type: SIGN_IN,
  payload: user,
});

export const signOutUser = () => ({
  type: SIGN_OUT,
});

export const toggleLoginLoading = (bool) => ({
  type: LOGIN_LOADING,
  status: bool,
});
