import { faPrayingHands } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useLayoutEffect } from "react";
import styles from "./PrayerRequestForm.module.css";

const PrayerRequestForm = (props) => {
  const submitHandler = props.submitHandler;
  const [showRequestForm, setShowRequestForm] = useState(false);
  const formRef = useRef();

  const clearRequest = (e) => {
    if (e) e.preventDefault();
    setShowRequestForm(false);
    if (formRef.current) formRef.current.reset();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      name: formData.get("name"),
      anonymous: formData.get("anonymous") ? true : false,
      email: formData.get("email"),
      phone: formData.get("phone"),
      public: formData.get("sharePublicly") === "yes" ? true : false,
      prayerRequest: formData.get("prayerRequest"),
    };
    submitHandler(data)
      .then(() => clearRequest())
      .catch((err) => console.error(err));
  };

  const RequestForm = () => {
    return (
      <div className={styles.requestFormWrapper}>
        <div
          className={styles.backdrop}
          onClick={() => setShowRequestForm(false)}
        ></div>
        <form
          className={styles.requestForm}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <h2>Prayer Request</h2>
          <fieldset>
            <legend>Name:</legend>
            <label>
              Name:
              <input name="name" id="requesterNameInput" type="input" />
            </label>
            <label>
              Remain Anonymous:
              <input
                name="anonymous"
                id="requsterAnonymousCheckbox"
                type="checkbox"
              />
            </label>
          </fieldset>
          <fieldset>
            <legend>Contact Info:</legend>
            <label>
              Email:
              <input
                name="email"
                id="requesterEmailInput"
                type="email"
                minLength="5"
                maxLength="100"
              />
            </label>
            <label>
              Phone:
              <input
                name="phone"
                id="requesterPhoneInput"
                type="tel"
                pattern="[\d\-+\(\) ]{10,17}"
              />
            </label>
          </fieldset>

          <fieldset>
            <legend>Share this publicly?</legend>
            <label>
              Yes
              <input
                type="radio"
                id="yes-public"
                value="yes"
                name="sharePublicly"
              />
            </label>
            <label>
              No
              <input
                type="radio"
                id="no-private"
                value="no"
                name="sharePublicly"
              />
            </label>
          </fieldset>
          <div className={styles.stackyField}>
            <label>
              <b>Prayer Request:</b>
              <textarea
                name="prayerRequest"
                id="prayerRequestInput"
                rows="7"
                cols="50"
              />
            </label>
          </div>

          <div className={styles.formButtons}>
            <button id="requestSubmit" name="requestSubmit">
              Submit
            </button>
            <button
              id="cancelSubmit"
              name="cancelSubmit"
              onClick={(e) => clearRequest(e)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  };

  const RequestFormButton = () => {
    const useWindowSize = () => {
      const [size, setSize] = useState([0, 0]);
      useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
      }, []);
      return size;
    };

    const [width, height] = useWindowSize();

    return (
      <>
        {width < 720 ? (
          <button
            className={styles.requestFormButton}
            onClick={() => setShowRequestForm(true)}
          >
            Request{" "}
            <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faPrayingHands} />
          </button>
        ) : (
          <button
            className={styles.requestFormButton}
            onClick={() => setShowRequestForm(true)}
          >
            Share your prayer request
          </button>
        )}
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <RequestFormButton />
      {showRequestForm && <RequestForm />}
    </div>
  );
};

export default PrayerRequestForm;
