import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { auth } from "../config";
import styles from "./PasswordReset.module.css";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);
  let { orgId } = useParams();
  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    }
  };

  const sendResetEmail = (event) => {
    event.preventDefault();
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setEmailHasBeenSent(true);
        setTimeout(() => {
          setEmailHasBeenSent(false);
        }, 3000);
      })
      .catch(() => {
        setError("Error resetting password");
      });
  };

  return (
    <div className={styles.signInBackground}>
      <div className={styles.signInWrapper}>
        <h1 className={styles.localH1}>Reset your Password</h1>
        <div>
          <form action="">
            {emailHasBeenSent && (
              <div className={styles.successMsg}>
                An email has been sent to you!
              </div>
            )}
            {error !== null && <div className={styles.errorMsg}>{error}</div>}
            <div className={styles.labelAndInput}>
              <label htmlFor="userEmail" className="w-full block">
                Email:
              </label>
              <input
                type="email"
                name="userEmail"
                id="userEmail"
                value={email}
                placeholder="Input your email"
                onChange={onChangeHandler}
              />
            </div>
            <button onClick={sendResetEmail}>Send me a reset link</button>
          </form>
          <br />
          <br />
          <Link to={`/${orgId}`}>&larr; back to sign in page</Link>
        </div>
      </div>
    </div>
  );
};
export default PasswordReset;
