import React, { Component, createContext } from "react";
import { auth } from "../config";

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = { user: null };
  }

  componentDidMount = () => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        userAuth.getIdToken().then(token => console.log('TOKEN', token));
      }
      this.setState({ user: userAuth});
      //   signInUser(this.state.user);
    });
  };

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
