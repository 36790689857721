import { createContext, useState } from "react";

export const View = {ADMIN: "ADMIN", PUBLIC: "PUBLIC", ARCHIVE: "ARCHIVE", MEMBER: "MEMBER"};
const isView = (isView, thisView) => () => isView === thisView;
const INITIAL_FUNCS = {
    isAdminView: () => false,
    isPublicView: () => true,
    isArchiveView: () => false,
    isMemberView: () => false
};
const INITIAL = {view: View.PUBLIC, ...INITIAL_FUNCS}
export const ViewContext = createContext(INITIAL);

export default function ViewProvider(props) {
    const funcs = {
        isAdminView: isView(props.view, View.ADMIN),
        isPublicView: isView(props.view, View.PUBLIC),
        isArchiveView: isView(props.view, View.ARCHIVE),
        isMemberView: isView(props.view, View.MEMBER)
    };
    const [view] = useState(() => {
        let viewState = {...INITIAL, ...funcs};
        if (props.view) return {...viewState, view:props.view};
        return viewState;
    });

    return (
        <ViewContext.Provider value={view}>
            {props.children}
        </ViewContext.Provider>
    )
}