import env from "../env"
import axios from "axios"

const api = axios.create({
    baseURL: env().apiBasePath
});

export const post = async (path, user, payload) => {
    let token = await user.getIdToken();
    return api.post(path, payload, {headers: {'Authorization': `Bearer ${token}`}});
}