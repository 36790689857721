import {post} from "./prayewallApi";

class UserService {
    createUser(user, payload) {
        return post('/user', user, payload);
    }

    getClaims(user, uid) {
        return post('/user/claims', user, {uid: uid});
    }

    grantAdmin(user, uid) {
        return post('/user/grantAdmin', user, {uid: uid});
    }

    removeAdmin(user, uid) {
        return post('/user/removeAdmin', user, {uid: uid});
    }

    grantMember(user, uid) {
        return post('/user/grantMember', user, {uid: uid});
    }

    removeMember(user, uid) {
        return post('/user/removeMember', user, {uid: uid});
    }

    addToOrg(user, orgId) {
        return post('/user/org', user, {orgId: orgId});
    }

    archive(user, uid) {
        return post('/user/archive', user, {uid: uid});
    }
}

export default new UserService();