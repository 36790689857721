import React from "react";
import "./App.css";
import UserProvider from "./providers/UserProvider";
import DbUserProvider from "./providers/DbUserProvider";
import Home from "./views/Home";
import { ToastProvider } from "react-toast-notifications";

function App() {
  return (
    <UserProvider>
      <DbUserProvider>
        <ToastProvider>
          <Home className="App" />
        </ToastProvider>
      </DbUserProvider>
    </UserProvider>
  );
}

export default App;
