import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import {
  faArchive,
  faPrayingHands,
  faCheck,
  faTrashRestore,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./PrayerButtons.module.css";
import { DbUserContext } from "../providers/DbUserProvider";
import { ViewContext } from "../providers/ViewProvider";

export default function PrayerButtons(props) {
  const { isAdmin } = useContext(DbUserContext);
  const viewContext = useContext(ViewContext);

  return (
    <div className={styles.buttonBank}>
      {isAdmin() && viewContext.isAdminView() && (
        <FontAwesomeIcon
          title="Approve"
          className={`${styles.icon} ${styles.archive}`}
          icon={faCheck}
          onClick={props.approveBtnHandler}
        />
      )}
      {isAdmin() && viewContext.isArchiveView() && (
        <FontAwesomeIcon
          title="Restore"
          className={`${styles.icon} ${styles.archive}`}
          icon={faTrashRestore}
          onClick={props.approveBtnHandler}
        />
      )}
      {isAdmin() && !viewContext.isArchiveView() && (
        <FontAwesomeIcon
          title="Archive"
          className={`${styles.icon} ${styles.archive}`}
          icon={faArchive}
          onClick={props.archiveBtnHandler}
        />
      )}
      {!viewContext.isArchiveView() && (
        <FontAwesomeIcon
          title="Pray for them"
          className={`${styles.icon} ${
            props.prayedFor ? styles.iconActive : ""
          }`}
          icon={faPrayingHands}
          onClick={props.prayerBtnHandler}
        />
      )}
      {/* <label className={styles.container} aria-label="Confirm Prayer">
        <input className={styles.icon} type="checkbox" />
        <span className={styles.checkmark}></span>
      </label> */}
    </div>
  );
}
