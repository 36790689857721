import React from "react";
import "firebase/auth";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import PasswordReset from "./PasswordReset";
import PrayerRequests from "./PrayerRequests";
import PrayerWall from "./PrayerWall";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  matchPath,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import { UserContext } from "../providers/UserProvider";
import ManageUsers from "./ManageUsers";
import MemberPrayerRequests from "./MemberPrayerWall";
import Splash from "./Splash";
import { DbUserContext } from "../providers/DbUserProvider";
import ArchivedRequests from "./ArchivedRequests";
import UserArchive from "./UserArchive";

class Home extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Routes />
        </Router>
      </div>
    );
  }
}

const Routes = (...rest) => {
  const userProviderAuth = React.useContext(UserContext);
  let { dbUser, isAdmin } = React.useContext(DbUserContext);
  const pathMatch = matchPath(useLocation().pathname, {
    path: "/:orgId",
    exact: false,
  });
  const orgId = pathMatch ? pathMatch.params.orgId : "";

  const authed = () => {
    return userProviderAuth && userProviderAuth.uid.length > 0 && dbUser;
  };
  return (
    <Switch>
      <Route exact path={"/"} component={Splash} />
      <ProtectedLogin
        exact
        path={"/:orgId"}
        component={SignIn}
        isAdmin={isAdmin()}
        auth={authed()}
        orgId={orgId}
      />
      <SignUpUnlessAuthed
        path={"/:orgId/sign-up"}
        auth={authed()}
        orgId={orgId}
        component={SignUp}
      />
      <Route path={"/:orgId/password-reset"} component={PasswordReset} />
      <Route path={"/:orgId/prayer-wall-embed"} component={PrayerWall} />
      <ProtectedRoute
        path={"/:orgId/dashboard"}
        auth={authed()}
        orgId={orgId}
        component={PrayerRequests}
      />
      <ProtectedRoute
        path={"/:orgId/prayer-wall"}
        auth={authed()}
        orgId={orgId}
        component={MemberPrayerRequests}
      />
      <ProtectedRoute
        path={"/:orgId/archive"}
        auth={authed()}
        orgId={orgId}
        component={ArchivedRequests}
      />
      <ProtectedRoute
        path={"/:orgId/user-archive"}
        auth={authed()}
        orgId={orgId}
        component={UserArchive}
      />
      <ProtectedRoute
        path={"/:orgId/manage-users"}
        auth={authed()}
        component={ManageUsers}
        orgId={orgId}
      />
    </Switch>
  );
};

const ProtectedLogin = ({ auth, component: Component, ...rest }) => {
  const dashboard = () => {
    if (auth && rest.isAdmin) {
      return "/dashboard";
    }
    return "/prayer-wall";
  };
  return (
    <Route
      {...rest}
      render={() =>
        !auth ? <Component /> : <Redirect to={"/" + rest.orgId + dashboard()} />
      }
    />
  );
};

const SignUpUnlessAuthed = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() =>
        !auth ? <Component /> : <Redirect to={"/" + rest.orgId} />
      }
    />
  );
};

const ProtectedRoute = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (auth ? <Component /> : <Redirect to={"/" + rest.orgId} />)}
    />
  );
};

function mapStateToProps(state) {
  return {
    user: state.loginReducer.user,
    loginLoading: state.loginReducer.loginLoading,
  };
}

export default connect(mapStateToProps)(Home);
