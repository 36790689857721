import React, { useState } from "react";
import styles from "./SidebarLayout.module.css";
import Navigation from "./Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";

export default function SidebarLayout(props) {
  const [menuOpen, menuOpenToggle] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.fixedLogo}>Prayerwall.church</div>
      <div className={styles.headerBackground}></div>
      <div
        style={menuOpen ? {} : { left: "-330px" }}
        className={styles.sideBar}
      >
        <button
          className={styles.menuToggleButton}
          onClick={() => menuOpenToggle(!menuOpen)}
        >
          <FontAwesomeIcon
            title="Menu"
            className={`${styles.icon} ${styles.archive}`}
            icon={menuOpen ? faTimes : faBars}
          />
        </button>

        <div className={styles.innerWrapper}>
          <div className={styles.logo}>Prayerwall.church</div>
          <h1 className={styles.localH1}>
            {props.pageTitle
              ? props.pageTitle
              : console.error("<SidebarLayout /> is missing props.pageTitle")}
          </h1>
          <Navigation />
        </div>
        <div className={styles.shadowBar}></div>
      </div>
      <div
        className={styles.mainContent}
        onClick={() => {
          if (menuOpen) {
            menuOpenToggle(!menuOpen);
          }
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
